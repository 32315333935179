<template>
  <v-card>
    <ErrorModal :error="error" @close-error-modal="error = null" />
    <WaitModal :show="showWaitModal" />

    <template v-if="hasPatientIotDevice === false">
      <v-toolbar
        :absolute="$vuetify.breakpoint.xl"
        dense
        :class="{
          'abs-right pt-1': $vuetify.breakpoint.smAndUp,
          'd-flex ': $vuetify.breakpoint.lgAndDown,
          'justify-end': $vuetify.breakpoint.md || $vuetify.breakpoint.lg,
          'justify-center': $vuetify.breakpoint.smAndDown,
        }"
        elevation="0"
      >
        <v-btn small color="primary" @click="openVitalSignsManualEntryModal">
          {{ $t('entryData') }}
        </v-btn>
      </v-toolbar>

      <VitalSignsManualEntry
        v-if="showVitalSignsManualEntry"
        :patient-id="patientId"
        :observations="observations"
        :show-manual-entry="showVitalSignsManualEntry"
        @updateChartData="getWeightData"
        @closeManualEntryModal="closeManualEntryModal"
      />
    </template>

    <ChartWeight
      ref="chartDetails"
      :chart-title="chartTitle"
      :chart-weight-unit="$t(selectedUnitWeight)"
      :y-axis="yAxis"
      :values="patientValues"
      :series="weightSeries"
      :filters="filters"
      :from-notification="fromNotification"
    />

    <v-row class="px-4">
      <v-col cols="auto">
        <v-select
          ref="unitWeight"
          v-model="selectedUnitWeight"
          item-text="name"
          item-value="code"
          :label="$t('unitWeight')"
          outlined
          dense
          hide-details
          :items="availableUnitsWeight"
        />
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import patientMonitoringService from '@/services/patientMonitoringService';
import translationMixin from '@/translationMixin';
import ChartWeight from '../Chart/ChartWeight.vue';
import VitalSignsManualEntry from '../ManualEntry/VitalSignsManualEntry.vue';
import filterMixin from '../filterMixin';
import patientVitalSignsTabMixin from './patientVitalSignsTabMixin';

export default {
  name: 'PatientWeight',
  components: {
    ChartWeight,
    VitalSignsManualEntry,
  },
  mixins: [translationMixin, filterMixin, patientVitalSignsTabMixin],

  props: {
    patientId: {
      type: Number,
      default: null,
    },

    filters: {
      type: Object,
      default: null,
    },

    activityId: {
      type: Number,
      default: null,
    },
  },

  data() {
    return {
      yAxis: {
        Ymax: 200,
        Ymin: 20,
        YStepSize: 5,
        title: 'weight',
      },
      yMaxInKg: null,
      yMinInKg: null,
      chartTitle: 'weightDetails',
      observations: [{ name: 'Weight', unitType: 'kg', value: null }],
      weightSeries: [],
      patientData: null,
      patientValues: null,
      originalPatientValues: null,
      hasPatientIotDevice: null,
      showWaitModal: false,
      error: null,
      requestFilters: null,
      fromNotification: false,
      selectedUnitWeight: 'kg',
      availableUnitsWeight: [
        {
          code: 'kg',
          name: this.$t('kilogram'),
        },
        {
          code: 'lbs',
          name: this.$t('pound'),
        },
      ],
      showVitalSignsManualEntry: false,
    };
  },

  computed: {
    dateFrom() {
      return this.calculateChartDate('start');
    },
    dateTo() {
      return this.calculateChartDate('end');
    },
  },

  watch: {
    patientId: function () {
      this.getWeightData();
    },

    filters: function () {
      this.getWeightData();
    },

    requestFilters: {
      handler: function (newVal, oldVal) {
        if (JSON.stringify(newVal) !== JSON.stringify(oldVal)) {
          this.$emit('update:request-filters', newVal);
          if ((oldVal && oldVal.fromNotification) || (newVal && newVal.fromNotification)) {
            this.fromNotification = true;
          }
        }
      },
      deep: true,
    },

    selectedUnitWeight: function () {
      this.convertUnitWeight();
    },
  },

  created() {
    this.fromNotification = this.requestFilters !== null;
    this.getWeightData();
  },

  methods: {
    async getWeightData() {
      const newFilters = this.setRequestFilters();
      this.requestFilters = newFilters;
      this.patientValues = [];
      this.weightSeries = [];

      this.requestFilters = {
        ...this.requestFilters,
        statusLevelIds: this.filters?.statusLevelIds,
      };

      try {
        this.showWaitModal = true;

        this.patientData = await patientMonitoringService.getPatientWeight(this.requestFilters);
        this.originalPatientValues = this.patientData.results.flat();
        this.hasPatientIotDevice = this.patientData.hasPatientIotDevice;

        this.patientValues = this.originalPatientValues;

        this.weightSeries = [
          {
            title: 'weight',
            thresholds: {
              thresholdVariation: this.patientData.thresholdVariation,
              title: 'weightThresholds',
            },
          },
        ];

        if (this.selectedUnitWeight === 'lbs') {
          this.convertUnitWeight();
        } else {
          let yValues = this.getYAxisOnWeightData();

          this.yMaxInKg = Math.round(yValues.maxWeight);
          this.yMinInKg = Math.round(yValues.minWeight);

          if (this.patientValues?.length > 0) {
            this.yAxis.Ymax = this.yMaxInKg;
            this.yAxis.Ymin = this.yMinInKg;
          }

          this.setThresholdsOnUnitType();
        }
      } catch (error) {
        this.error = error;
      }

      this.showWaitModal = false;
    },

    getYAxisOnWeightData() {
      let maxWeight;
      let minWeight;

      if (this.patientValues?.length > 0) {
        maxWeight = Math.max(...this.patientValues.map((x) => x.values.at(0).value)) + 15;
        minWeight = Math.min(...this.patientValues.map((x) => x.values.at(0).value)) - 15;
      } else {
        maxWeight = this.yAxis.Ymax;
        minWeight = this.yAxis.Ymin;
      }

      return {
        minWeight: minWeight,
        maxWeight: maxWeight,
      };
    },

    convertUnitWeight: function () {
      let yValues = this.getYAxisOnWeightData();

      if (this.selectedUnitWeight === 'lbs') {
        this.yAxis.Ymax = Math.round(yValues.maxWeight * 2.2);
        this.yAxis.Ymin = Math.round(yValues.minWeight * 2.2);
        this.yAxis.YStepSize = 10;
      } else {
        this.yAxis.Ymax = Math.round(this.yMaxInKg);
        this.yAxis.Ymin = Math.round(this.yMinInKg);
        this.yAxis.YStepSize = 5;
      }

      this.patientValues = this.originalPatientValues.map((weight) => {
        return {
          time: weight.time,
          values: weight.values.map((weightValue) => {
            return {
              alertDetails: weightValue.alertDetails,
              hasTriggeredAnAlert: weightValue.hasTriggeredAnAlert,
              isManualEntryValueByPatient: weightValue.isManualEntryValueByPatient,
              userManualEntry: weightValue.userManualEntry,
              value:
                weightValue.value && this.selectedUnitWeight === 'lbs'
                  ? parseFloat((weightValue.value * 2.2).toFixed(1))
                  : parseFloat(weightValue.value.toFixed(1)),
            };
          }),
        };
      });

      this.setThresholdsOnUnitType();
    },

    setThresholdsOnUnitType: function () {
      let thresholdMin = this.patientData.thresholdMin;
      let thresholdMax = this.patientData.thresholdMax;

      if (this.selectedUnitWeight === 'lbs') {
        this.weightSeries[0].thresholds.minThreshold = thresholdMin
          ? thresholdMin.unit === 'kg'
            ? Math.round(thresholdMin.value * 2.2)
            : thresholdMin.value
          : null;

        this.weightSeries[0].thresholds.maxThreshold = thresholdMax
          ? thresholdMax.unit === 'kg'
            ? Math.round(thresholdMax.value * 2.2)
            : thresholdMax.value
          : null;
        return;
      }

      this.weightSeries[0].thresholds.minThreshold = thresholdMin
        ? thresholdMin.unit === 'kg'
          ? thresholdMin.value
          : Math.round(thresholdMin.value / 2.2)
        : null;

      this.weightSeries[0].thresholds.maxThreshold = thresholdMax
        ? thresholdMax.unit === 'kg'
          ? thresholdMax.value
          : Math.round(thresholdMax.value / 2.2)
        : null;
    },
  },
};
</script>

<style scoped>
.abs-right {
  right: 4px;
}
</style>
