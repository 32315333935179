var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.showManualEntry)?_c('v-dialog',{ref:"manualEntryDialog",attrs:{"value":"showManualEntry","scrollable":"","persistent":"","max-width":"600px"}},[_c('v-card',[_c('ErrorModal',{attrs:{"error":_vm.error},on:{"close-error-modal":function($event){_vm.error = null}}}),_c('WaitModal',{attrs:{"show":_vm.isProcessing}}),_c('ModalTitle',{attrs:{"title":_vm.$t('manualEntry')}}),_c('v-card-text',[_c('v-form',{ref:"manualEntryForm",attrs:{"lazy-validation":""}},[_vm._l((_vm.observations),function(observation,index){return _c('v-row',{key:index},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{ref:"manualEntryValue",refInFor:true,staticClass:"required-indicator",attrs:{"label":_vm.$t(observation.name.charAt(0).toLowerCase() + observation.name.slice(1)),"rules":[
                _vm.validationRules.required,
                observation.name === 'Saturation' ? _vm.validationRules.atMost(100) : true,
                _vm.validationRules.atLeast(0),
                observation.name === 'Weight' || observation.name === 'BodyTemperature'
                  ? true
                  : _vm.validationRules.integer,
              ],"suffix":observation.unitType ? observation.unitType : _vm.$t(`${observation.name}Suffix`),"append-icon":['Weight', 'BodyTemperature'].includes(observation.name) ? 'mdi-autorenew' : '',"type":"number","hide-spin-buttons":"","filled":"","dense":""},on:{"click:append":function($event){observation.unitType =
                  observation.name === 'Weight'
                    ? observation.unitType === 'kg'
                      ? 'lb'
                      : 'kg'
                    : observation.unitType === '°C'
                    ? '°F'
                    : '°C'}},model:{value:(observation.value),callback:function ($$v) {_vm.$set(observation, "value", $$v)},expression:"observation.value"}})],1)],1)}),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('DateTimePickerField',{ref:"manualEntryDateTime",attrs:{"max-date":new Date(),"label":_vm.$t('dataDateTime'),"picker-type":"dateTime","required":""},model:{value:(_vm.dataDateTime),callback:function ($$v) {_vm.dataDateTime=$$v},expression:"dataDateTime"}})],1)],1),_c('RequiredFieldsLegend')],2)],1),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"text":""},on:{"click":_vm.closeManualEntryModal}},[_vm._v(_vm._s(_vm.$t('cancel')))]),_c('SaveButton',{attrs:{"is-loading":_vm.isLoading,"is-processing":_vm.isProcessing,"show-wait-modal":_vm.showWaitModal,"handle-click":_vm.saveManualEntryData}})],1)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }