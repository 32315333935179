export default {
  en: {
    chatListTab: 'Chat List',
    notificationListTab: 'Notifications',
    newChat: 'New Chat',
    activeChats: 'Active Chats',
    archivedChats: 'Archived Chats',
    noChatHistory: 'No conversation history to display',
    noPreviewAvailable: 'No preview available',
    chat: 'Chat',
    patientVideoCall: 'Video Call',
    joinedTheCall: '{name} joined the call',
    leftTheCall: '{name} left the call',
    endChat: 'End Conversation',
    confirmEndChat: 'If you end the conversation, it will be in read-only mode and no new messages can be added.',
    lastReadMessage: 'Last Read Messages',
    typeMessage: 'Type a message',
    today: 'Today',
    multipleHours: '"multiples hours"',
    takenAt: 'taken at',
    caregiver: 'Caregiver',
    caregivers: 'Caregivers',
    incontinenceValues: 'Incontinence Values',
    trainingNotCompleted: 'Training not completed',
    trainingCompletedAt: 'Training completed at',

    'patientVideoCall.camera': 'Camera',
    'patientVideoCall.cameraOn': 'Camera\n(on)',
    'patientVideoCall.microphone': 'Microphone',
    'patientVideoCall.microphoneOn': 'Microphone\n(on)',
    'patientVideoCall.RotateCamera': 'Rotate\ncamera',
    'patientVideoCall.endCall': 'End\ncall',
    'patientVideoCall.userDisconnected': 'User disconnected...',
    'patientVideoCall.waitingForParticipant': 'You are currently the only one on the call',
    'patientVideoCall.connectionMessage': 'An error occurred while connecting the call.',
    'patientVideoCall.disconnectedMessage': 'An error has occurred and you have been disconnected from the call.',
    'patientVideoCall.videoError': 'An error occurred while activating the camera',
    'patientVideoCall.microphoneError': 'An error occurred while activating the microphone',

    'patientDetails.fromGustav': 'The patient information comes from the Gustav software',
    'patientDetails.openInGustav': 'View in Gustav',

    'patientChartUnit.heartRate': 'bpm',
    'patientChartUnit.bloodPressure': 'mmHg',
    'patientChartUnit.saturation': '%',
    'patientChartUnit.respiratoryRate': 'Breathing cycles',
    'patientChartUnit.respiratoryRateLegendary': 'Breathing cycle',
    'patientChartUnit.bodyTemperature': '°C',
    'patientChartUnit.triggeredAlert': 'Triggered Alert',
    'patientChartUnit.thresholdValue': 'Threshold Value',

    oneOfPatientObservationAlreadyExists: 'One of the observations already exists for the patient at the selected time',
    patientWeighingAlreadyExists: 'There is already a weight measurement for the patient at the selected time',

    ConversationCompletedException: 'This conversation is archived',

    question: 'Question',
    response: 'Answer',
    answeredAt: 'answered at',
    notAnswered: 'Anamnesis not answered',
    showAnswers: 'Show all answers',
    hideAnswers: 'Hide all answers',
    expressAnswer: 'The patient used the choice "my situation has not changed"',

    PRN: 'PRN',

    patientNotFound: 'Patient not found',

    manualEntry: 'Manual Entry',
    entryData: 'Enter Data',
    SystolicSuffix: 'mmHg',
    DiastolicSuffix: 'mmHg',
    PulseSuffix: 'bpm',
    SaturationSuffix: '%',
    BodyTemperatureSuffix: '°C',
    RespiratoryRateSuffix: 'rpm',
    dataDateTime: 'Data Time',
    valueManualyEnteredByPatient: 'Value manually entered by the patient',
    valueManualyEnteredByUser: 'Value manually entered by {{userName}}',
  },
  fr: {
    chatListTab: 'Conversations',
    notificationListTab: 'Notifications',
    newChat: 'Nouvelle conversation',
    activeChats: 'Conversations actives',
    archivedChats: 'Conversations terminées',
    noChatHistory: 'Aucun historique de conversation à afficher',
    noPreviewAvailable: 'Aucun aperçu disponible',
    chat: 'Clavardage',
    patientVideoCall: 'Appel vidéo',
    joinedTheCall: "{name} a joint l'appel",
    leftTheCall: "{name} a quitté l'appel",
    endChat: 'Terminer la conversation',
    confirmEndChat:
      'Si vous terminez la conversation, celle-ci sera en mode lecture et aucun nouveau message ne pourra être ajoutée.\n\nSouhaitez-vous vraiment mettre fin à la conversation ?',
    lastReadMessage: 'Dernière lecture',
    typeMessage: 'Saisissez votre réponse',
    today: "Aujourd'hui",
    multipleHours: '"heures multiples"',
    takenAt: 'pris à',
    caregiver: 'Proche aidant',
    caregivers: 'Proches aidants',
    incontinenceValues: 'Incontinence',
    trainingNotCompleted: 'Formation non-complétée',
    trainingCompletedAt: 'Formation complétée à',

    'patientVideoCall.camera': 'Caméra',
    'patientVideoCall.cameraOn': 'Caméra\n(activée)',
    'patientVideoCall.microphone': 'Microphone',
    'patientVideoCall.microphoneOn': 'Microphone\n(activé)',
    'patientVideoCall.RotateCamera': 'Rotation\ncaméra',
    'patientVideoCall.endCall': "Terminer\nl'appel",
    'patientVideoCall.userDisconnected': 'Utilisateur déconnecté...',
    'patientVideoCall.waitingForParticipant': "Vous êtes présentement seul sur l'appel",
    'patientVideoCall.connectionMessage': "Une erreur est survenue lors de la connexion à l'appel.",
    'patientVideoCall.disconnectedMessage': "Une erreur est survenue et vous avez été déconnecté de l'appel.",
    'patientVideoCall.videoError': "Une erreur est survenue lors de l'activation de la caméra",
    'patientVideoCall.microphoneError': "Une erreur est survenue lors de l'activation du microphone",

    'patientDetails.fromGustav': 'Les informations du patient proviennent du logiciel Gustav',
    'patientDetails.openInGustav': 'Consulter dans Gustav',

    'patientChartUnit.heartRate': 'bpm',
    'patientChartUnit.bloodPressure': 'mmHg',
    'patientChartUnit.saturation': '%',
    'patientChartUnit.respiratoryRate': 'Cycles de respiration',
    'patientChartUnit.respiratoryRateLegendary': 'Cycle de respiration',
    'patientChartUnit.bodyTemperature': '°C',
    'patientChartUnit.triggeredAlert': 'Alerte déclenchée',
    'patientChartUnit.thresholdValue': 'Seuil alerte',

    oneOfPatientObservationAlreadyExists: "Une des observations existe déjà pour le patient à l'heure choisie",
    patientWeighingAlreadyExists: "Une pesée est déjà enregistrée pour le patient à l'heure choisie",

    ConversationCompletedException: 'Cette conversation est terminée',

    question: 'Question',
    response: 'Réponse',
    answeredAt: 'répondu à',
    notAnswered: 'Anamnèse non-répondue',
    showAnswers: 'Afficher toutes les réponses',
    hideAnswers: 'Masquer toutes les réponses',
    expressAnswer: `Le patient a utilisé le choix "ma situation n'a pas changé"`,

    PRN: 'PRN',

    patientNotFound: 'Patient non-trouvé',

    manualEntry: 'Entrée manuelle',
    entryData: 'Entrer une donnée',
    SystolicSuffix: 'mmHg',
    DiastolicSuffix: 'mmHg',
    PulseSuffix: 'bpm',
    SaturationSuffix: '%',
    BodyTemperatureSuffix: '°C',
    RespiratoryRateSuffix: 'rpm',
    dataDateTime: 'Heure de la donnée',
    valueManualyEnteredByPatient: 'Valeur entrée manuellement par le patient',
    valueManualyEnteredByUser: 'Valeur entrée manuellement par {{userName}}',
  },
};
